import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactModal from 'react-modal';
import { Box, Button,  Grid, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import _ from 'lodash';
import { withFirebase } from '../_firebase';
import 'firebase/firestore';
import { googlePlayServiceAccounts } from '../_constants/googlePlayServiceAccounts';

ReactModal.setAppElement('#root')

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 10, 10, 0.45)',
        zIndex: 9999
    },
    content: {
        height: '80%',
        maxWidth: '50%',
        margin: 'auto',
        scrollY: 'auto'
    }
};

const useStyles = makeStyles(() => ({
    title: {
        marginTop: 0
    },
    sectionTitle: {
        fontSize: 16,
        color: '#000',
        margin: "20px 0 10px 0"
    },
    helpText: {
        fontSize: 14,
        color: '#3C4252',
        lineHeight: 1.2,
        fontWeight: '400',
    },
    helpLink: {
        fontWeight: 'bold',
        marginTop: '10px'
    },
    saveButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    propertyContainer: {
        '& a': {
            color: 'black',
            textDecoration: 'none'
        }
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 0,
        fontSize: 12
    },
    value: {
        display: '-webkit-box',
        margin: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        fontSize: 12
    },
    iconButton: {
        marginLeft: 5
    },
    icon: {
        fontSize: 14
    },
}))

function ServiceAccountModal({ data, isOpen, onClose }) {
    const classes = useStyles();

    const copyToClipboard = (value) =>
        navigator.clipboard.writeText(value)

    function ServiceAccountProperty({ label, name }) {
        return (
            <div className={classes.propertyContainer}>
                <p className={classes.label}> {`${label}`}
                    <IconButton className={classes.iconButton} onClick={() => copyToClipboard(name)}>
                        <FileCopyIcon className={classes.icon} />
                    </IconButton>
                </p>
                <p className={classes.value}>{name || '-'}</p>
            </div>
        )
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            style={customStyles}
        >
            <Grid container direction='column' xs={12}>
                <h2 align="center" className={classes.title}>Conta de Serviço Google Play</h2>

                <Box>
                        <h2 className={classes.sectionTitle}>
                            Passo 1
                        </h2>

                        <h3 className={classes.helpText}>
                            Copie o <strong>email</strong> de uma das Contas de Serviço a abaixo para conectar à conta de 
                            desenvolvedor Google Play do Cliente.
                        </h3>

                        <Box mb={4} mt={2}>
                            {data.firebaseProjectName 
                                ? googlePlayServiceAccounts.map((item, index) => (
                                    <ServiceAccountProperty 
                                        key={index} 
                                        label={`Email da conta de serviço ${index + 1}`} 
                                        name={`${item}@${data.firebaseProjectName}.iam.gserviceaccount.com`} 
                                    />
                                ))
                                : <h3 className={classes.helpText}>
                                    É necessário um projeto do firebase
                                </h3>
                            }
                        </Box>

                        <h3 className={classes.helpText}>
                            <strong>Obs:</strong> Há mais de uma conta de serviço, pois o limite de integração 
                            são 10 contas de desenvolvedor por conta de serviço.
                        </h3>

                        <h2 className={classes.sectionTitle}>
                            Passo 2
                        </h2>

                        <h3 className={classes.helpText}>
                            Siga os passos do Tutorial para fazer a conexão
                        </h3>

                        <Box mb={2} mt={2}>
                            <a
                                className={classes.helpLink}
                                href="https://docs.google.com/document/d/1u__ow3BD49L9_2-mlAzBlTrt851Zomz1wvQ8O9J6Rmk"
                                target="blank"
                            >
                                Conectando a Conta de Serviço Google à uma Conta de Desenvolvedor
                            </a>
                        </Box>

                        <h3 className={classes.helpText}>
                            <strong>Obs:</strong> Caso ocorra um erro relacionado ao limite de integrações, 
                            copie outro email e repita o processo.
                        </h3>

                        <h2 className={classes.sectionTitle}>
                            Passo 3
                        </h2>

                        <Box mb={2} mt={2}>
                            <h3 className={classes.helpText}>
                                Selecione o <strong>nome da conta de serviço da qual você copiou o email</strong> no modal de 
                                Atualização das Informações de Publicação.
                            </h3>

                        </Box>
                </Box>
                <Box className={classes.saveButton} mt={2}>
                    <Button variant="contained" color="primary" onClick={onClose}>
                        Fechar
                    </Button>
                </Box>
            </Grid>
        </ReactModal>
    )
}

export default withFirebase(ServiceAccountModal);