import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box, ListSubheader, Menu, MenuItem } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import clsx from 'clsx';
import { withFirebase } from '../_firebase';
import AuthUserContext from '../_auth/context';
import { useHistory } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: '#fff',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerToolbar: {
    alignItems: 'center',
    backgroundColor: '#3C4252',
    display: 'flex',
    justifyContent: 'center'
  },
  drawerPaper: {
    backgroundColor: '#3C4252',
    color: '#fff',
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  subheader: {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: 12,
    textTransform: 'uppercase'
  },
  logo: {
    height: 35
  },
  title: {
    textTransform: 'capitalize'
  }
}));

function MainLayout(props) {
  const history = useHistory();

  const { children, firebase, title, window } = props;

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={clsx(classes.toolbar, classes.drawerToolbar)}>
        <img className={classes.logo} src={require('../_assets/img/mobilizei_title.png').default} />
      </div>
      <Divider />
      <List>
        <ListSubheader component="div" id="nested-list-subheader" className={classes.subheader}>
          Plataformas
        </ListSubheader>

        <ListItem button dense>
          <ListItemText primary={'Convertr'} onClick={() => history.push('/platforms/convertr/stores')} />
        </ListItem>
        
        <ListItem button dense>
          <ListItemText primary={'Nuvemshop'} onClick={() => history.push('/platforms/nuvemshop/stores')} />
        </ListItem>

        <ListItem button dense>
          <ListItemText primary={'Tray'} onClick={() => history.push('/platforms/tray/stores')} />
        </ListItem>

        <ListItem button dense>
          <ListItemText primary={'Webview'} onClick={() => history.push('/platforms/webview/stores')} />
        </ListItem>

        <ListItem button dense>
          <ListItemText primary={'Wix'} onClick={() => history.push('/platforms/wix/stores')} />
        </ListItem>

        <ListSubheader component="div" id="nested-list-subheader" className={classes.subheader}>
          Geral
        </ListSubheader>

        <ListItem button dense>
          <ListItemText primary={'Leads'} onClick={() => history.push('/leads')} />
        </ListItem>

        <ListItem button dense>
          <ListItemText primary={'Usuários'} onClick={() => history.push('/users')} />
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Box alignItems="center" display="flex" width="100%" flexDirection="row" justifyContent="space-between">
                <Box alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" noWrap className={classes.title}>
                    {title}
                  </Typography>
                </Box>
                <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem dense disabled onClick={handleClose}>{authUser.email}</MenuItem>
                    <Divider />
                    <MenuItem dense onClick={() => firebase.doSignOut()}>Sair</MenuItem>
                  </Menu>
                </div>
              </Box>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {children}
          </main>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}

export default withFirebase(MainLayout);