import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactModal from 'react-modal';
import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import _ from 'lodash';
import { withFirebase } from '../_firebase';
import 'firebase/firestore';
import { googlePlayServiceAccounts } from '../_constants/googlePlayServiceAccounts';
import ServiceAccountModal from './ServiceAccountModal';
import { expoAccountNames } from '../_constants/expoAccountNames';

ReactModal.setAppElement('#root')

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 10, 10, 0.45)',
        zIndex: 9999
    },
    content: {
        height: '88%',
        maxWidth: '70%',
        margin: 'auto',
        scrollY: 'auto'
    }
};

const useStyles = makeStyles(() => ({
    title: {
        marginTop: 0
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 6,
        marginBottom: 10,
    },
    sectionTitle: {
        fontSize: 16,
        color: '#3C4252',
    },
    sectionSubtitle: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#3C4252',
    },
    helpButton: {
        position: 'absolute',
        top: 12,
        right: 0
    },
    helpText: {
        fontSize: 16,
        color: '#3C4252',
        fontWeight: 'bold',
        margin: '10px 0 0 0'
    },
    infoTextBold: {
        color: '#3C4252',
        fontWeight: 'bold',
    },
    helpLink: {
        fontWeight: 'bold',
        marginTop: '10px'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    saveButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}))

function UpdateStoreDataDialog({ data, firebase, isOpen, onClose, onDataUpdate, platformId }) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [serviceAccountModal, setServiceAccountModal] = useState(false)

    const [formData, setFormData] = useState({
        expoProjectId: _.get(data, 'expoProjectId', ''),
        expoAccountName: _.get(data, 'expoAccountName', ''),
        slug: _.get(data, 'slug', ''),
        firebaseProjectName: _.get(data, 'firebaseProjectName', ''),
        platforms: {
            android: {
                ...data.platforms?.android,
                googlePlayServiceAccountName: _.get(data, 'platforms[android][googlePlayServiceAccountName]', ''),
                currentBuildVersion: _.get(data, 'platforms[android][currentBuildVersion]', ''),
                firebaseApplicationId: _.get(data, 'platforms[android][firebaseApplicationId]', ''),
            },
            ios: {
                ...data.platforms?.ios,
                teamID: _.get(data, 'platforms[ios][teamID]', ''),
                teamName: _.get(data, 'platforms[ios][teamName]', ''),
                currentBuildVersion: _.get(data, 'platforms[ios][currentBuildVersion]', ''),
                firebaseApplicationId: _.get(data, 'platforms[ios][firebaseApplicationId]', ''),
            },
        },
        facebookId: _.get(data, 'facebookId', ''),
        facebookClientToken: _.get(data, 'facebookClientToken', ''),
    });

    const [errors, setErrors] = useState({
        status: null,
        trialEndsAt: null
    })

    const handleChange = (e) => {
        let newFormData = { ...formData };
        //set value without spaces
        _.set(newFormData, e.target.name, e.target.value.trim())
        setFormData(newFormData);
    }

    const handleSave = () => {
        setLoading(true);

        const errors = {};
        setErrors({});

        if (_.isEmpty(errors)) {
            firebase.updateStore(platformId, data.id, formData)
                .then(() => {
                    onDataUpdate();
                    onClose();
                })
                .catch((e) => {
                    console.log(e)
                    alert(`Não foi possível salvar as informações da loja`)
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false);
            setErrors(errors);
        }
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            style={customStyles}
        >
            <Grid container direction='column' xs={12}>
                <h2 align="center" className={classes.title}>Atualizar informações de publicação</h2>

                <Grid container direction='row' xs={12} spacing={2}>
                    <Grid item direction='column' xs={6}>
                        <h2 className={classes.sectionTitle}>
                            Expo
                        </h2>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>ID do Projeto</InputLabel>
                            <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                <TextField
                                    name="expoProjectId"
                                    variant="standard"
                                    size='medium'
                                    defaultValue={formData.expoProjectId}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Box>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>Slug</InputLabel>
                            <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                <TextField
                                    name="slug"
                                    variant="standard"
                                    size='medium'
                                    defaultValue={formData.slug}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Box>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>Nome da conta</InputLabel>
                            <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                <Select
                                    native
                                    name="expoAccountName"
                                    value={formData.expoAccountName}
                                    label="Selecione o nome da conta Expo"
                                    onChange={handleChange}
                                >
                                    <option value=''>Selecione o nome da conta Expo</option>
                                    {expoAccountNames.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <h2 className={classes.sectionTitle}>
                            Google Play
                        </h2>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>Nome da conta de serviço</InputLabel>
                            {formData.firebaseProjectName 
                                ? <>
                                    <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                        <Select
                                            native
                                            name="platforms[android][googlePlayServiceAccountName]"
                                            value={formData.platforms.android.googlePlayServiceAccountName}
                                            label="Selecione a conta de serviço"
                                            onChange={handleChange}
                                        >
                                            <option value=''>Selecione a conta de serviço</option>
                                            {googlePlayServiceAccounts.map((item, index) => (
                                                <option key={index} value={item}>{item}</option>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <Button
                                        style={{ padding: 0, marginTop: 10, textTransform: "none" }}
                                        onClick={() => setServiceAccountModal(true)}
                                    >
                                        Como preencher
                                    </Button>
                                </>
                                : <InputLabel className={classes.helpText} shrink>
                                    É necessário um projeto do firebase para preencher
                                </InputLabel>
                            }
                        </Box>

                        <h2 className={classes.sectionTitle}>
                            Apple
                        </h2>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>Nome da empresa</InputLabel>
                            <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                <TextField
                                    name="platforms[ios][teamName]"
                                    variant="standard"
                                    size='medium'
                                    defaultValue={formData.platforms.ios.teamName}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Box>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>Team ID</InputLabel>
                            <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                <TextField
                                    name="platforms[ios][teamID]"
                                    variant="standard"
                                    size='medium'
                                    defaultValue={formData.platforms.ios.teamID}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Box>

                        <h2 className={classes.sectionTitle}>
                            Facebook (Opcional)
                        </h2>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>ID do Facebook</InputLabel>
                            <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                <TextField
                                    name="facebookId"
                                    variant="standard"
                                    size='medium'
                                    defaultValue={formData.facebookId}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Box>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>Token do Facebook</InputLabel>
                            <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                <TextField
                                    name="facebookClientToken"
                                    variant="standard"
                                    size='medium'
                                    defaultValue={formData.facebookClientToken}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item direction='column' xs={6}>
                        <h2 className={classes.sectionTitle}>
                            Firebase
                        </h2>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>Código do Projeto do Firebase</InputLabel>
                            {data.firebaseProjectName
                                ? <InputLabel className={classes.helpText} shrink>
                                    {data.firebaseProjectName}
                                </InputLabel>
                                : <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                    <TextField
                                        name="firebaseProjectName"
                                        variant="standard"
                                        size='medium'
                                        defaultValue={formData.firebaseProjectName}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            }
                        </Box>
                        
                        <h2 className={classes.sectionSubtitle}>
                            Android
                        </h2>

                        <Box mb={2} mt={2}>
                            {formData.platforms?.android?.bundleId 
                                ? <InputLabel htmlFor="status" shrink>ID do Aplicativo do Firebase</InputLabel>
                                : <InputLabel htmlFor="status" shrink>ID do Aplicativo do Firebase (caso esteja em branco será criado automaticamente)</InputLabel>
                            }
                            <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                <TextField
                                    name="platforms[android][firebaseApplicationId]"
                                    variant="standard"
                                    size='medium'
                                    defaultValue={formData.platforms.android.firebaseApplicationId}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            {data.firebaseProjectName && data.platforms?.android?.bundleId &&
                                (<a
                                    className={classes.helpLink}
                                    href={`https://console.firebase.google.com/u/1/project/${data.firebaseProjectName}/settings/general/android:${data.platforms.android.bundleId}`}
                                    target="blank"
                                >
                                    Você pode obter aqui
                                </a>)
                            }
                        </Box>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>Bundle ID (caso esteja em branco será criado automaticamente)</InputLabel>
                            {data.platforms?.android?.bundleId
                                ? <InputLabel className={classes.helpText} shrink>
                                    {data.platforms.android.bundleId}
                                </InputLabel>
                                : <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                    <TextField
                                        name="platforms[android][bundleId]"
                                        variant="standard"
                                        size='medium'
                                        defaultValue={formData.platforms.android.bundleId}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            }
                        </Box>

                        <h2 className={classes.sectionSubtitle}>
                            iOS
                        </h2>

                        <Box mb={2} mt={2}>
                            {formData.platforms?.ios?.packageName
                                ? <InputLabel htmlFor="status" shrink>ID do Aplicativo do Firebase</InputLabel>
                                : <InputLabel htmlFor="status" shrink>ID do Aplicativo do Firebase (caso esteja em branco será criado automaticamente)</InputLabel>
                            }
                            <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                <TextField
                                    name="platforms[ios][firebaseApplicationId]"
                                    variant="standard"
                                    size='medium'
                                    defaultValue={formData.platforms.ios.firebaseApplicationId}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            {data.firebaseProjectName && data.platforms?.ios?.packageName &&
                                (<a
                                    className={classes.helpLink}
                                    href={`https://console.firebase.google.com/u/1/project/${data.firebaseProjectName}/settings/general/ios:${data.platforms.ios.packageName}`}
                                    target="blank"
                                >
                                    Você pode obter aqui
                                </a>)
                            }
                        </Box>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>Nome do pacote (caso esteja em branco será criado automaticamente)</InputLabel>
                            {data.platforms?.ios?.packageName
                                ? <InputLabel className={classes.helpText} shrink>
                                    {data.platforms.ios.packageName}
                                </InputLabel>
                                : <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                    <TextField
                                        name="platforms[ios][packageName]"
                                        variant="standard"
                                        size='medium'
                                        defaultValue={formData.platforms.ios.packageName}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            }
                        </Box>

                        <h2 className={classes.sectionTitle}>
                            Versões atuais (caso houver)
                        </h2>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>Android</InputLabel>
                            <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                <TextField
                                    name="platforms[android][currentBuildVersion]"
                                    variant="standard"
                                    size='medium'
                                    defaultValue={formData.platforms.android.currentBuildVersion}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            {data.platforms?.android?.downloadUrl && (
                                <a
                                    className={classes.helpLink}
                                    href={data.platforms.android.downloadUrl}
                                    target="blank"
                                >
                                    Você pode obter aqui
                                </a>
                            )}
                            <InputLabel className={classes.helpText} shrink>
                                Também é encontrado na conta de desenvolvedor do cliente
                            </InputLabel>
                        </Box>

                        <Box mb={2} mt={2}>
                            <InputLabel htmlFor="status" shrink>iOS</InputLabel>
                            <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                                <TextField
                                    name="platforms[ios][currentBuildVersion]"
                                    variant="standard"
                                    size='medium'
                                    defaultValue={formData.platforms.ios.currentBuildVersion}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            {data.platforms?.ios?.downloadUrl && (
                                <a
                                    className={classes.helpLink}
                                    href={data.platforms.ios.downloadUrl}
                                    target="blank"
                                >
                                    Você pode obter aqui
                                </a>
                            )}
                            <InputLabel className={classes.helpText} shrink>
                                Também é encontrado na conta de desenvolvedor do cliente
                            </InputLabel>
                        </Box>



                    </Grid>
                </Grid>
                <Box className={classes.saveButton} mt={4}>
                    {loading ? <CircularProgress /> :
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Salvar
                        </Button>
                    }
                </Box>

                <ServiceAccountModal
                    data={formData}
                    isOpen={serviceAccountModal}
                    onClose={() => setServiceAccountModal(false)}
                />
            </Grid>
        </ReactModal>
    )
}

export default withFirebase(UpdateStoreDataDialog);