import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LaunchIcon from '@material-ui/icons/Launch';
import { formatFirestoreTimestamp } from '../_helpers/mappers';
import JsonModal from './JsonModal';
import SetPublishStatusModal from './SetPublishStatusModal';
import ChangeStoreStatusDialog from './ChangeStoreStatusDialog';
import config from '../_helpers/config'
import UpdateStoreDataDialog from './UpdateStoreDataDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowWrap: 'anywhere',
    padding: theme.spacing(2),
  },
  propertyContainer: {
    '& a': {
      color: 'black',
      textDecoration: 'none'
    }
  },
  label: {
    fontWeight: 'bold',
    marginBottom: 0
  },
  value: {
    display: '-webkit-box',
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  iconButton: {
    marginLeft: 5
  },
  icon: {
    fontSize: 16
  },
  image: {
    border: 'solid 1px #ececec',
    height: 'auto',
    width: 70
  },
  buttonsContainer: {
    '& > *': {
      marginRight: theme.spacing(2),
    },
  }
}));

export default function DetailsPanel({ onDataUpdate, platformId, store }) {
  const classes = useStyles();

  const [showChangeStoreStatusDialog, setShowChangeStoreStatusDialog] = useState(false);
  const [showJsonModal, setShowJsonModal] = useState(false);
  const [showSetPublishStatusModal, setShowSetPublishStatusModal] = useState(false);
  const [showUpdateStoreDataDialog, setShowUpdateStoreDataDialog] = useState(false);

  const accessTokenFieldName = 'accessToken';

  const createdAtFieldName = 'createdAt';
  const updatedAtFieldName = 'updatedAt';

  const androidData = _.get(store, 'platforms.android', {});
  const iOSData = _.get(store, 'platforms.ios', {});

  const copyToClipboard = (value) =>
    navigator.clipboard.writeText(value)

  function TextProperty({ data, label, name }) {
    return (
      <div className={classes.propertyContainer}>
        <p className={classes.label}> {`${label}`}
          <IconButton className={classes.iconButton} onClick={() => copyToClipboard(data[name])}>
            <FileCopyIcon className={classes.icon} />
          </IconButton>
        </p>
        <p className={classes.value}>{data[name] || '-'}</p>
      </div>
    )
  }

  function ImageProperty({ data, label, name }) {
    return (
      <div className={classes.propertyContainer}>
        <a href={data[name]} target="_blank">
          <p className={classes.label}> {`${label}`}
            <IconButton className={classes.iconButton}>
              <LaunchIcon className={classes.icon} />
            </IconButton>
          </p>
          {_.isEmpty(data[name]) ? '-' : <img className={classes.image} src={data[name]} />}
        </a>
      </div>
    )
  }

  function DateProperty({ data, label, name }) {
    return (
      <div className={classes.propertyContainer}>
        <p className={classes.label}> {`${label}`}</p>
        <p className={classes.value}>{formatFirestoreTimestamp(data[name], 'DD/MM/YYYY HH:mm') || '-'}</p>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={2}>
          <TextProperty data={store} label="ID" name={'id'} />
          <TextProperty data={store} label="Access token" name={accessTokenFieldName} />
          <TextProperty data={store} label="Slug" name="slug" />
          <TextProperty data={store} label="ID do Projeto Expo" name="expoProjectId" />
          <TextProperty data={store} label="Nome da conta Expo" name="expoAccountName" />
          <TextProperty data={store} label="Status" name="status" />
          <TextProperty data={store} label="Tema ativo" name="activeTheme" />
          <TextProperty data={store} label="Projeto do Firebase" name="firebaseProjectName" />
          <DateProperty data={store} label="Fim do período de testes" name="trialEndsAt" />
          <DateProperty data={store} label="Data de cadastro" name={createdAtFieldName} />
          <DateProperty data={store} label="Última atualização" name={updatedAtFieldName} />
        </Grid>

        <Grid item xs={3}>
          <TextProperty data={store} label="Título do aplicativo" name="title" />
          <TextProperty data={store} label="Descrição curta" name="shortDescription" />
          <TextProperty data={store} label="Descrição" name="description" />
          <TextProperty data={store} label="Url da Política de Privacidade" name="privacyPolicyUrl" />
          <TextProperty data={store} label="Palavras chave" name="keywords" />
          <TextProperty data={store} label="Vídeo promocional" name="promotionalVideo" />
          <TextProperty data={store} label="ID do Facebook" name="facebookId" />
          <TextProperty data={store} label="Token do Facebook" name="facebookClientToken" />
          {store.platformId === 'nuvemshop' && <TextProperty data={store} label="Plano da Nuvemshop" name="nuvemshopPlanName" />}
        </Grid>

        <Grid item xs={3}>
          <h2>Android</h2>
          <TextProperty data={androidData} label="Bundle ID" name="bundleId" />
          <TextProperty data={androidData} label="Build version" name="currentBuildVersion" />
          <TextProperty data={androidData} label="ID do Aplicativo Firebase" name="firebaseApplicationId" />
          <TextProperty data={androidData} label="Url" name="downloadUrl" />
          <TextProperty data={androidData} label="Status" name="publishStatus" />
          <TextProperty data={androidData} label="Nome da conta Google" name="developerAccountName" />
          <TextProperty data={androidData} label="Email da conta Google" name="developerAccountEmail" />
          <TextProperty data={androidData} label="Nome da conta de serviço" name="googlePlayServiceAccountName" />
          <DateProperty data={androidData} label="Última solicitação de publicação" name="lastPublishRequestedAt" />
          <DateProperty data={androidData} label="Última atualização de status" name="publishStatusUpdatedAt" />
        </Grid>

        <Grid item xs={2}>
          <h2>iOS</h2>
          <TextProperty data={iOSData} label="Package name" name="packageName" />
          <TextProperty data={iOSData} label="Build version" name="currentBuildVersion" />
          <TextProperty data={iOSData} label="ID do Aplicativo Firebase" name="firebaseApplicationId" />
          <TextProperty data={iOSData} label="Url" name="downloadUrl" />
          <TextProperty data={iOSData} label="Status" name="publishStatus" />
          <TextProperty data={iOSData} label="Team Name" name="teamName" />
          <TextProperty data={iOSData} label="Team ID" name="teamID" />
          <DateProperty data={iOSData} label="Última solicitação de publicação" name="lastPublishRequestedAt" />
          <DateProperty data={iOSData} label="Última atualização de status" name="publishStatusUpdatedAt" />
        </Grid>

        <Grid item xs={2}>
          <ImageProperty data={store} label="Ícone" name="iconUrl" />
          <ImageProperty data={store} label="Tela de introdução" name="launchScreenUrl" />
          <ImageProperty data={store} label="Banner da Play Store" name="featureGraphicUrl" />
        </Grid>

        <Grid alignItems="center" className={classes.buttonsContainer} container direction="row" item justify="flex-start" xs={12}>
          <Button variant="contained" onClick={() => setShowSetPublishStatusModal(true)}>Alterar status de publicação</Button>
          <Button variant="contained" onClick={() => setShowChangeStoreStatusDialog(true)}>Alterar status da loja</Button>
          <Button variant="contained" onClick={() => setShowUpdateStoreDataDialog(true)}>Atualizar informações de publicação</Button>
          <Button variant="contained" onClick={() => setShowJsonModal(true)}>Ver JSON</Button>
        </Grid>
      </Grid>

      <UpdateStoreDataDialog
        data={store}
        isOpen={showUpdateStoreDataDialog}
        onClose={() => setShowUpdateStoreDataDialog(false)}
        onDataUpdate={onDataUpdate}
        platformId={platformId}
      />

      <ChangeStoreStatusDialog
        data={store}
        isOpen={showChangeStoreStatusDialog}
        onClose={() => setShowChangeStoreStatusDialog(false)}
        onDataUpdate={onDataUpdate}
        platformId={platformId}
      />

      <JsonModal
        data={store}
        isOpen={showJsonModal}
        onClose={() => setShowJsonModal(false)}
      />

      <SetPublishStatusModal
        data={store}
        isOpen={showSetPublishStatusModal}
        onClose={() => setShowSetPublishStatusModal(false)}
        onDataUpdate={onDataUpdate}
        platformId={platformId}
      />
    </div>
  );
}