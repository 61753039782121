import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import AppRouter from './_routers/AppRouter';
import Firebase, { FirebaseContext } from './_firebase';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <AppRouter />
    </ThemeProvider>
  </FirebaseContext.Provider>,
  document.querySelector('#root'),
);