export const expoAccountNames = [
    "nuvemshop1",
    "tray1",
    "tray2",
    "tray3",
    "tray4",
    "webview1",
    "wix1",
    "convertr1",
    "convertr2",
]