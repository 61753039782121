import fileSaver from 'file-saver';

export function convertArrayToCsv(array, header = '', separator = ';') {
    let BOM = "\uFEFF";
    let str = BOM + header + '\r\n';

    array = typeof array != 'object' ? JSON.parse(array) : array;

    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            if (line != '') line += separator

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    let blob = new Blob([str], { type: "text/csv;charset=utf-8" });
    fileSaver.saveAs(blob, "data.csv");
}